import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/website/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "running-locally"
    }}>{`Running Locally`}</h1>
    <p>{`We support Mac primarily and linux with best-efforts.`}</p>
    <h2 {...{
      "id": "setup"
    }}>{`Setup`}</h2>
    <p>{`This takes about 30 mins to download ~2gb of tools on a fresh mac on a 12mbps connection.`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Open Terminal `}{`(`}{`CMD + Space, type Terminal`}{`)`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Install xcode command line tools.`}</p>
        <pre parentName="li"><code parentName="pre" {...{}}>{`xcode-select --install
`}</code></pre>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Create or just cd into your development folder`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-text"
          }}>{`cd ~
mkdir development
cd development
`}</code></pre>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Checkout this repository`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-text"
          }}>{`git clone https://github.com/cadbox1/glue-stack.git
cd glue-stack
`}</code></pre>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Run the setup bash script`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-text"
          }}>{`sh setup.sh
`}</code></pre>
        <p parentName="li">{`You can open the bash script to find out but it:`}</p>
        <ul parentName="li">
          <li parentName="ul">{`installs Homebrew`}</li>
          <li parentName="ul">{`installs Java`}</li>
          <li parentName="ul">{`installs Docker`}</li>
          <li parentName="ul">{`installs Maven`}</li>
          <li parentName="ul">{`installs Node`}</li>
          <li parentName="ul">{`installs Yarn`}</li>
        </ul>
        <p parentName="li">{`Hit Enter when you're prompted.
You will need to enter your password for both Homebrew and Docker. Type it in but be aware that password characters aren't displayed in terminal.
You'll need to enter your password again when Docker first opens.`}</p>
      </li>
    </ol>
    <h2 {...{
      "id": "running"
    }}>{`Running`}</h2>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Create a new terminal tab `}{`(`}{`CMD + t`}{`)`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Start the MySQL database using`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-text"
          }}>{`docker-compose up
`}</code></pre>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Create a new terminal tab `}{`(`}{`CMD + t`}{`)`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`The next tab will run the api `}{`(`}{`backend`}{`)`}{`.`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-text"
          }}>{`cd api
mvn spring-boot:run
`}</code></pre>
        <p parentName="li">{`This will start our Spring Boot Java application which will setup the tables in our database using a tool called Flyway.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`The next tab will run our ui `}{`(`}{`frontend`}{`)`}{` development server. You won't need this in production.`}</p>
      </li>
    </ol>
    <pre><code parentName="pre" {...{
        "className": "language-text"
      }}>{`cd ui
yarn
yarn start
`}</code></pre>
    <p>{`This will open a browser window to our application. Make sure both servers are started before your start playing with it.`}</p>
    <ol {...{
      "start": 11
    }}>
      <li parentName="ol">{`Signup your organisation!`}</li>
      <li parentName="ol">{`To stop any of the components hit `}<inlineCode parentName="li">{`control + c`}</inlineCode>{` in the tab. This is how to stop running terminal programs.`}</li>
    </ol>
    <p>{`Now that you've got it running perhaps you'd like to try `}<a parentName="p" {...{
        "href": "./DevelopmentProcess-Tasks.md"
      }}>{`developing glue-stack`}</a>{`?`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      